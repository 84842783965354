import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({ components, directives });

createApp(App).use(vuetify).mount("#app");


// apiKey: 'AIzaSyBU5MK0zWCY_paMmwVq5I7arOxIQkfN7vg',     