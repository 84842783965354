<template>
  <div class="flex justify-center p-6">
    <form class="w-1/2" id="submit-form">
      <v-form ref="form">
        <div class="space-y-12" name="pickupRequest" id="pickupRequest">
          <div class="pb-4">
            <h2 class="text-2xl font-semibold text-white my-3">
              Pickup Request
            </h2>
            <p class="mt-1 text-sm leading-6 text-white">
              Please fill out the form below and someone will get back to you
              with confirmation of a time and day for pickup.
            </p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <v-text-field
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  :rules="requiredRule"
                  v-model="form.firstName"
                ></v-text-field>
              </div>

              <div class="sm:col-span-3">
                <v-text-field
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  v-model="form.lastName"
                ></v-text-field>
              </div>

              <div class="sm:col-span-3">
                <v-text-field
                  name="email"
                  label="Email"
                  variant="outlined"
                  v-model="form.email"
                ></v-text-field>
              </div>

              <div class="sm:col-span-3">
                <v-text-field
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  :rules="requiredRule"
                  v-model="form.phoneNumber"
                ></v-text-field>
              </div>

              <div class="col-span-full">
                <v-text-field
                  name="streetAddress"
                  label="Street Address"
                  variant="outlined"
                  :rules="requiredRule"
                  v-model="form.streetAddress"
                ></v-text-field>
              </div>

              <div class="sm:col-span-2 sm:col-start-1">
                <v-text-field
                  name="suburb"
                  label="Suburb"
                  variant="outlined"
                  :rules="requiredRule"
                  v-model="form.suburb"
                ></v-text-field>
              </div>

              <div class="sm:col-span-2">
                <v-text-field
                  name="state"
                  label="State"
                  variant="outlined"
                  v-model="form.state"
                ></v-text-field>
              </div>

              <div class="sm:col-span-2">
                <v-text-field
                  name="postcode"
                  label="Postcode"
                  variant="outlined"
                  v-model="form.postcode"
                ></v-text-field>
              </div>

              <div class="sm:col-span-full">
                <v-text-field
                  name="numContainers"
                  label="Approx number of containers"
                  variant="outlined"
                  v-model.number="form.numContainers"
                  v-on:keyup="changeCan()"
                  :rules="requiredRule"
                ></v-text-field>
                <p class="font-bold" v-html="containerError"></p>
              </div>

              <div class="sm:col-span-full">
                <v-select
                  name="reimburseType"
                  label="Cash or Member Number?"
                  :items="[
                    'Select Reimbursement Type',
                    'Cash',
                    'Member Number',
                  ]"
                  variant="outlined"
                  v-model="form.reimburseType"
                ></v-select>
              </div>

              <div
                class="sm:col-span-full"
                v-if="form.reimburseType == 'Member Number'"
              >
                <v-text-field
                  name="memberNumber"
                  label="Member Number"
                  variant="outlined"
                  v-model="form.memberNumber"
                ></v-text-field>
              </div>

              <div class="sm:col-span-full" v-if="form.reimburseType == 'Cash'">
                <v-textarea
                  name="cashLeft"
                  label="Where should the cash be left?"
                  variant="outlined"
                  v-model="form.cashLeft"
                ></v-textarea>
              </div>

              <div class="sm:col-span-full">
                <v-textarea
                  name="containersLocated"
                  label="Where are containers located?"
                  variant="outlined"
                  :rules="requiredRule"
                  v-model="form.containersLocated"
                ></v-textarea>
              </div>
            </div>
          </div>
        </div>
        <div
          class="p-4 mb-4 mt-5 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
          role="alert"
          v-if="successText"
          v-html="successText"
        ></div>
        <div
          class="p-4 mb-4 mt-5 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
          v-if="errorText"
          v-html="errorText"
        ></div>
        <div class="mt-6 flex items-center justify-end gap-x-6">
          <v-btn variant="outlined" @click="validate"
            ><strong>Submit Pickup Request</strong>
          </v-btn>
        </div>
      </v-form>
    </form>
  </div>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
export default {
  components: { VueGoogleAutocomplete },
  data: () => ({
    address: "",
    numofcans: 0,
    containerError: "",
    requiredRule: [(v) => !!v || `Field is required`],
    form: {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      streetAddress: null,
      suburb: null,
      state: null,
      postcode: null,
      numContainers: 0,
      reimburseType: "Select Reimbursement Type",
      memberNumber: null,
      cashLeft: null,
      containersLocated: null,
    },
    successText: null,
    errorText: null,
  }),
  mounted() {},
  methods: {
    changeCan() {
      if (this.numofcans < 200) {
        this.containerError = "Minimum pickup is 200 containers.";
      }
      if (this.numofcans >= 200 && this.numofcans < 400) {
        this.containerError =
          "Under 400 containers pricing will be 8c per container.";
      }
      if (this.numofcans >= 400) {
        this.containerError =
          "Pricing over 400 containers is 10c per container.";
      }
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const formData = axios.toFormData(this.form);

        axios({
          method: "post",
          url: "./send_mail/submit.php",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          if (response.data.status === 202) {
            document.getElementById("submit-form").reset();
            this.successText = `<span class="font-medium"Form Sent!</span> Email has been sent. Phone call will be incoming to confirm day.`;
          } else {
            this.errorText = `<span class="font-medium"Form Failed!</span> Please call on 0000 0000 to place your request`;
          }
          console.log(response);
        });
      }
    },
  },
};
</script>
<style>
body {
  background-color: #40b46e;
}
#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
label {
  color: #fff;
}
</style>
